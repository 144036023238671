.custom-table {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td, th {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
  }
}


.table-measurement {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td, th {
    border: 1px solid black;
    text-align: left;
    padding: 3px;
    font-weight: normal;
  }
}

.react-transform-wrapper {
  height: 100% !important;
}

.placeholder-color {
  color: #131722 !important;
}

.placeholder-color::placeholder {
  color: #131722;
  opacity: 1;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 100%; 
  height: auto;
}

.hotspot {
  position: absolute;
  cursor: pointer;
}

.hotspot-1 {
  top: 0%; 
  left: 48%; 
}

.hotspot-2 {
  top: 91%; 
  left: 48%; 
}

.icon {
  width: 50px;
  height: 50px;
  opacity: 0.3; 
  color: black;
  transition: opacity 0.3s;
}

.icon:hover {
  color: black;
  opacity: 1; 
}