$primary: #ff7200;
$form-label-font-weight: 400;
$input-group-addon-bg: #fff;
.btn-primary {
  color: #fff !important;
}
.react-datepicker__day--selected {
  background-color: $primary !important;
}
.react-datepicker__time-list-item--selected {
  background-color: $primary !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: $primary !important;
  opacity: 0.75;
  color: #fff !important;
}
@import '~bootstrap/scss/bootstrap';

.rdt_Table {
  font-family: $font-family-base;
  font-weight: bold;
}
.rdt_TableHeadRow {
  font-weight: bold;
  font-size: 1rem;
}

.leaflet-container {
  pointer-events: none;
}

.leaflet-container:hover {
  pointer-events: auto;
}
.z-index-0 {
  z-index: 0;
}
.min-height-sm-300{
  min-height: 500px;
}
.border-dotted{
  border-style: dotted;
 }
 
 .border-dashed {
  --bs-border-style: dashed;
}

.day {
  display: none;
}

.month {
  display: none;
}

.year {
  display: none;
}